

module.exports.getConfig = function()
{       
    return {
                environment: 'local',
                version: "50.15.141",
                apiServer: 'http://localhost:5191/api',
                reportingServer: 'http://localhost:5191/api',
                integrationServer: 'http://localhost:5191/api',
                gatewayServer: 'http://localhost:5191/api',
                appUrlWebOrdering: 'http://localhost:3191',

                mainPortalURL: "https://aa-b.portal.flipdishdev.com/flipdish-global/home",
                mainPortalBaseUrl: "https://aa-b.portal.flipdishdev.com",
                
                cookieAuthenticationApiUrl: 'http://localhost:5191/api',
                singleSignOnUrl: '',
                auth0Domain: "dev-xq1w8nkatpmtulrh.eu.auth0.com",
                portalAuth0ClientId: "sVVANSTQYfif8QAHN2qG3919LaT51p3d",

                cdnCloudUrl: 'https://res.cloudinary.com',
                cdnUploadPresetName: 'rms-jinoby-preset',
                cdnAppCloudName: 'rms-jinoby',
                cdnUsersCloudName: 'rms-uat-jinoby',

                appUrlPOS: 'http://localhost:5691',
                appUrlKiosk: 'http://localhost:5591',
                appUrlKDS: 'http://localhost:5491',
                appUrlKitchen: 'http://localhost:5991',
                appUrlCentralKitchen: 'http://localhost:5481',
                appUrlSignagePlayer: 'http://localhost:5791',
                
                menuCheckerEnabled: true,
                menuCheckerCutOff: "2023-12-01",
                menuSpellCheckerEnabled: false,
                menuSpellCheckerToken: '98e82707138341bd90bf3b7ede17ce44',

                googleMapsApiKey: 'AIzaSyDVCopNzpU1crTXtRTDtKkoOTZonDznLNY',

                messagingPlatformApiKey: "63n2Kw.dbighQ:1StjtuSiQmqy_RVxZWR4rYXGs2Nq3BWYQJml_sNwQok",

                agGridLicenseKey: "CompanyName=Jinoby Technologies Ltd,LicensedApplication=Jinoby,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-031656,SupportServicesEnd=16_August_2023_[v2]_MTY5MjE0MDQwMDAwMA==528d34bab3d00b7a3563dcb4230afc21",

                themeConfigUrl: "https://sarmsdevtheme.z35.web.core.windows.net",
                themeVersion: "30.0.0",

                dataDogClientToken: 'pubaf4145b736da8f2456f5b1c830be1538',
                dataDogSite: 'us3.datadoghq.com',

                dataDogRumApplicationIdForBackOffice: '4f2ddefd-ea52-4759-b566-d28c53133531',
                dataDogRumClientTokenForBackOffice: 'pubbdbd73fa9faeeb5009cd2439305237fe',
                dataDogRumSiteForBackOffice: 'us3.datadoghq.com',


                grammarlyApiKey: 'client_6uTcXohsLm5z9REUR8vXUh',
                grammarlyEnabled: true,
                reportDataLimitDays: JSON.stringify({
                    "salesByMenuItem": 7
                }),

                modulesMainPortalShiftingDate: "15/11/2024",
                showMainPortalBanner: false,
         }
}  


























































