
import React, { Component } from 'react';
import { Card, CardHeader,  CardBody, CardFooter, Button, Modal, Input} from 'reactstrap';
import Autocomplete from 'react-autocomplete';

const stateManager = require('../../utils/state/stateManager');
const rmsApiProxy = require('../../utils/api/rmsApiProxy');
const stringUtil = require('../../utils/string/stringUtil');
const securityManager = require('../../utils/domain/security/securityManager');
const actionDispatcher = require('../../utils/state/actionDispatcher');
const displayAccountSwitchAction = require('../../utils/state/actions/views/container/displayAccountSwitchAction');
const actionTypes = require('../../utils/state/actionTypes');
const cloudinaryUtils = require('../../utils/cloudinary/cloudinaryUtils');

class AccountSwitch extends Component
{
    constructor(props) 
    {
        super(props);

        this.onContinue = this.onContinue.bind(this);
        this.searchFranchisors = this.searchFranchisors.bind(this);

        this.state = {};
        this.state.timeout = null;
        this.state.currentSearchingQuery = "";
        this.state.isSearching = false;

        this.state.selectedFranchisor = null;
        this.state.loadedFranchisors = [];

    }

    render()
    {
        if (!this.isRemoteSearchingAllowed())
        {
            this.state.loadedFranchisors = stateManager.getStore().getState().availableFranchisors ?? [];
            if(!stringUtil.isStringNullOrEmpty(this.state.currentSearchingQuery))
            {
                this.state.loadedFranchisors = this.state.loadedFranchisors.filter(x => 
                    x.name.toLowerCase().includes(this.state.currentSearchingQuery.toLowerCase())
                    || x.code.toLowerCase().includes(this.state.currentSearchingQuery.toLowerCase())
                );
            }

        }

        var searchComponent = this.renderSearchComponent();
        const continueDisabled = this.state.selectedFranchisor == null;

        var removeAccountButton = null;

        if (securityManager.isSystemUser() && stateManager.getStore().getState().currentFranchisorId != null)
        {
            removeAccountButton = <Button color="danger" className="btn-danger" onClick={this.removeCurrentAccount}>
                                    Switch To System
                                 </Button>
        }

        return <Modal isOpen={true} centered animation={false} fade={false}  size="lg" style={{maxWidth: '1600px', width: '90%'}}>
                    <Card>
                        <CardHeader>
                            <h3 style={{marginTop:'10px'}}>Switch Account</h3>
                        </CardHeader>
                        <CardBody style={{paddingTop:'50px', paddingBottom: '50px'}}>
                            {searchComponent}
                        </CardBody>
                        
                        <CardFooter style={{textAlign:'end'}}>
                            
                            <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{width:'1%', whiteSpace:'nowrap'}}>
                                        {removeAccountButton}
                                    </td>

                                    <td style={{width:'99%', textAlign:'end'}}>
                                        <Button color="secondary" className="btn-secondary" style={{marginRight:'10px'}} onClick={this.onCancel}>
                                                Cancel
                                        </Button>

                                        <Button color="primary" className="btn-primary" disabled={continueDisabled} onClick={this.onContinue}>
                                            Continue
                                        </Button>

                                        
                                    </td>
                                </tr>
                            </table>
                            
                        </CardFooter>
                    </Card>
                </Modal>
    }

    renderSearchComponent()
    {
        let getFranchisorDisplayName = (franchisor)=>`${franchisor.name} (${franchisor.code})`;
        
        
        let renderFranchisor = (franchisor, isHighlighted) => 
                                {
                                    let url = franchisor.logoImageUrl ?? "";
                                    if (!url.startsWith('http'))
                                    {
                                        url = cloudinaryUtils.getUrl(franchisor.logoImageUrl);
                                    }

                                    return <div style={{ background: isHighlighted ? 'lightgray' : 'white',padding:'10px' }} key={franchisor.id}>
                                            <span>
                                                <img src={url} width="35vw"/>
                                                <span style={{marginLeft:'10px'}}>{franchisor.name} ({franchisor.code})</span>
                                            </span>
                                        </div>
                                };

        let renderMenu = (children) =>
        {
            if (this.state.loadedFranchisors.length > 0)
            {
                return <div style={{zIndex:'1000', maxHeight:'40vh', position: 'absolute', top: '100%', left: 0, right: 0, background: 'white', border: '1px solid #ccc', borderRadius: '10px', padding: '2px', overflow: 'auto'}}>
                            {children}
                        </div>
            }

            if (stringUtil.isStringNullOrEmpty(this.state.currentSearchingQuery))
            {
                return <div></div>;
            }

            if (this.state.isSearching)
            {
                return <div style={{zIndex:'1000', maxHeight:'40vh', position: 'absolute', top: '100%', left: 0, right: 0, background: 'white', border: '1px solid #ccc', borderRadius: '10px', padding: '2px', overflow: 'auto'}}>
                            <div style={{padding:'20px', color:' #A8A8A8'}}>Loading...</div>
                        </div>
            }

            return <div style={{zIndex:'1000', maxHeight:'40vh', position: 'absolute', top: '100%', left: 0, right: 0, background: 'white', border: '1px solid #ccc', borderRadius: '10px', padding: '2px', overflow: 'auto'}}>
                                <div style={{padding:'20px', color:' #A8A8A8'}}>No Org Found</div>
                    </div>
       
            
        }
        return <Autocomplete
                    wrapperStyle={{ position: 'relative', display: 'inline-block', width:'100%',zIndex:'1000' }}
                    inputProps={{ id: 'states-autocomplete', style:{width:'100%', background:'white', border:'1px solid #ccc', borderRadius:'10px', padding:'10px'}, placeholder:'Search for an organization'}}
                    menuStyle={{zIndex:'1000', position: 'absolute', top: '100%', left: 0, right: 0, background: 'white', border: '1px solid #ccc', borderRadius: '10px', padding: '2px', overflow: 'auto'}}
                    autoHighlight={true}

                    renderItem={renderFranchisor}
                    renderMenu={renderMenu}
                    
                    value={this.state.value}
                    items={this.state.loadedFranchisors}
                    getItemValue={getFranchisorDisplayName}
                    onChange={(event, value) =>
                                {

                                    this.state.value = value;
                                    this.state.currentSearchingQuery = value;
                                    this.state.selectedFranchisor = null;

                                    if (!this.isRemoteSearchingAllowed())
                                    {
                                        this.setState({}); 
                                        return;
                                    }
                                    
                                    this.state.isSearching = true;
                                    this.state.loadedFranchisors = [];
                                    this.setState({}); 

                                    if (this.state.timeout != null) clearTimeout(this.state.timeout);
                                    this.state.timeout = setTimeout(() => this.searchFranchisors(), 400);
                                   
                                }}
                    onSelect={value => 
                            {
                                this.state.selectedFranchisor = this.state.loadedFranchisors.find(x => getFranchisorDisplayName(x) == value); 
                                this.state.value = value;
                                this.setState({})
                            }}
                   
                    
        />
    }

    async searchFranchisors()
    {
        let query = this.state.currentSearchingQuery;
        if (stringUtil.isStringNullOrEmpty(query))
        {
            this.state.isSearching = false;
            return;
        }
        
        let returnedResults  = await rmsApiProxy.get(`core/organizations/franchisors?searchCriteria=${encodeURIComponent(stringUtil.trimString(query))}`);
        if (this.state.currentSearchingQuery != query)
        {
            return;
        }

        this.state.loadedFranchisors = returnedResults;
        this.state.isSearching = false;
        console.log(this.state.loadedFranchisors)
        this.setState({});
    }

    
    
    onContinue()
    {
        const franchisor = this.state.selectedFranchisor;
        stateManager.getStore().getState().currentFranchisorId = franchisor.id;
        stateManager.getStore().getState().sideBarSelectedOption = "modules";
        stateManager.getStore().getState().topBarSelectedOption = "expanded";
        stateManager.getStore().getState().mainBarComponent = null;
        stateManager.getStore().getState().mainBarOptions = null;
        stateManager.getStore().getState().mainBarSearch = null;

        actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
        actionDispatcher.dispatch({ type: actionTypes.organizationInitialized, payload: false });
    }

    async removeCurrentAccount()
    {
        stateManager.getStore().getState().currentFranchisorId = null;
        stateManager.getStore().getState().sideBarSelectedOption = "modules";
        stateManager.getStore().getState().topBarSelectedOption = "expanded";
        stateManager.getStore().getState().mainBarComponent = null;
        stateManager.getStore().getState().mainBarOptions = null;
        stateManager.getStore().getState().mainBarSearch = null;

        actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
        actionDispatcher.dispatch({ type: actionTypes.organizationInitialized, payload: false });
    }

    onCancel()
    {
        actionDispatcher.dispatch(displayAccountSwitchAction.createAction(false))
    }

    isRemoteSearchingAllowed()
    {
        return securityManager.isSystemUser();
    }
}

export default AccountSwitch;