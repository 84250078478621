import React, { Component } from 'react';

const configurationManager = require('../../../utils/config/configurationManager');

class MainPortalBanner extends Component
{
    render()
    {
        return  <div
                    style={{ 
                        paddingLeft: "30px", 
                        paddingRight: "30px", 
                        paddingTop: "25px", 
                        paddingBottom: "25px", 
                        background: '#FDF2CD', 
                        borderWidth: "3px", 
                        borderColor: "#B2B0A6",
                        borderStyle: "solid",
                        borderRadius: "10px"
                    }}>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "black" }}>
                        <p style={{marginBottom:'0.5rem'}}> 
                            <b>Update: </b>
                            All services in this portal can now be configured in the main Flipdish Portal. This portal will be permanently decommissioned by {configurationManager.getConfig().modulesMainPortalShiftingDate}. 
                            Until then, you can access these services in both places. <br/>
                        </p>
                        <p className='mb-0'>
                            For more information you can read&nbsp; 
                            <a href="https://docs.google.com/document/d/1bNOMvY4pAOt3CObIHnpsMG8fN_Dbcob8NbbBXOJWgo0/edit"><b>this helpdesk article</b></a>. 
                            To go to the main Flipdish portal, click&nbsp;
                            <a href={configurationManager.getConfig().mainPortalURL}><b>here</b></a>.
                        </p>
                    </div>
                </div>
    }
}

export default MainPortalBanner;