import React, { useEffect, useState } from "react";
import DateRangeComponent from "../../../components/form/dateRangeComponent";
import Select from "react-select";
import { Button } from "reactstrap";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import AuditLogDetailContent from "./AuditLogDetailContent";
import auditLogConstants from "../../../utils/constants/auditConstants";
import AuditLogTable from "./AuditLogTable";
import viewUtil from "../../../utils/view/viewUtil";
import styled from "styled-components";
import Spinner from "../../../components/spinner/spinner";
import {Modal, ModalHeader, ModalBody} from "reactstrap";

const currentOrgNodeSelectors = require("../../../utils/state/stateSelectors/currentOrgNodeSelectors");

const dateUtil = require("../../../utils/dateUtil/dateUtil");
const rmsApiProxy = require("../../../utils/api/rmsApiProxy");

const franchisorAuditLogModule = {
    FranchisorManagement: auditLogConstants.module.FranchisorManagement,
    MenuManagement: auditLogConstants.module.MenuManagement,
};

const propertyAuditLogModule = {
    SaleManagement: auditLogConstants.module.SaleManagement,
    MenuManagement: auditLogConstants.module.MenuManagement,
    PropertyManagement: auditLogConstants.module.PropertyManagement,
    StaffManagement: auditLogConstants.module.StaffManagement,
    TerminalCashDrawer: auditLogConstants.module.TerminalCashDrawer,
    TerminalInternetConnectivity: auditLogConstants.module.TerminalInternetConnectivity,
};

export default function AuditLogs({
    popupView = false,
    display = true,
    title = "Audit Logs",
    showToolbar = true,
    showDetailedView = true,
    showTitle = true,
    showDivider = true,
    toggleDisplay = () => {},
    searchParams
})
{
    const [state, setState] = useState({
        logs: [],
    });

    const [selectedLogDetails, setSelectedLogDetails] = useState(null);
    const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
    const [loading, setLoading] = useState(false);

    function getAuditLogModuleOptions() {
        let filteredAuditLogs = {};

        if (currentOrgNodeSelectors.isCurrentFranchisorSelected()) {
            filteredAuditLogs = franchisorAuditLogModule;
        }

        if (currentOrgNodeSelectors.isCurrentPropertySelected()) {
            filteredAuditLogs = propertyAuditLogModule;
        }

        return Object.values(filteredAuditLogs).map(value => ({
            label: value.replaceAll(".", " "),
            value,
        }));
    }

    const toggleDrawer = () => {
        setIsOpenDrawer((prevState) => !prevState);
    };

    const [form, setForm] = useState({
        moduleOption: undefined,
        startDate: dateUtil.getToday(),
        endDate: dateUtil.getToday(),
    });

    const handleOnLogExpandClick = (event) => {
        setSelectedLogDetails(event.data);
        toggleDrawer();
    };

    const handleOnApply = async () => {
        await loadSaleAuditLogs();
    };

    const prepareSearchCriteria = () => 
    {
        const { propertyId, franchiseeId, franchisorId } = currentOrgNodeSelectors.selectCurrentOrgContext();
        const dateTimeFormat = dateUtil.getDateTimeFormat();

        const searchCriteriaEntity = {};
        searchCriteriaEntity.franchisorId = franchisorId;
        searchCriteriaEntity.franchiseeId = franchiseeId;
        searchCriteriaEntity.propertyId = propertyId;

        searchCriteriaEntity.module = searchParams ? searchParams.module : form.moduleOption.value;
        searchCriteriaEntity.fromDate = searchParams ? searchParams.startDate : dateUtil.formatDate(`${form.startDate} 00:00:00`, dateTimeFormat);
        searchCriteriaEntity.toDate = searchParams ? searchParams.endDate : dateUtil.formatDate(`${form.endDate} 00:00:00`, dateTimeFormat);
        searchCriteriaEntity.objectId = searchParams ? searchParams.objectId : null;

        return searchCriteriaEntity;
    }

    const loadSaleAuditLogs = async () => {
        try {
            
            const searchCriteriaEntity = prepareSearchCriteria();

            setLoading(true);
            const result = await rmsApiProxy.put(`gateway/audits`, searchCriteriaEntity);
            setLoading(false);
            
            setState((prev) => ({ ...prev, logs: result }));
        } catch (error) {
            viewUtil.closeModalSpinner();
            viewUtil.showErrorAlert(`Error occurred while loading logs: ${error}`);
        }
    };

    useEffect(() => 
    {
        if (searchParams && searchParams.module)
            loadSaleAuditLogs();
    }, []);

    const LogDetailsView = () => {
        return (
            <Drawer open={isOpenDrawer} onClose={toggleDrawer} direction="right" size={"450px"}>
                <AuditLogDetailContent logDetails={selectedLogDetails} />
            </Drawer>
        )
    }
    
    const ToolBar = () => {
        return (
            <div className="mb-4">
                <div className="d-flex flex-row gap-2">
                    <DateRangeComponent
                        disabled={false}
                        startDate={form.startDate}
                        endDate={form.endDate}
                        onDateRangeChange={(startDate, endDate) =>
                        {
                            setForm((prev) => ({
                                ...prev,
                                startDate,
                                endDate,
                            }));
                        }}
                    />
                    <CustomSelect
                        clearable={false}
                        value={form.moduleOption}
                        options={getAuditLogModuleOptions()}
                        onChange={(optionValue) =>
                        {
                            setForm((prev) => ({ ...prev, moduleOption: optionValue }));
                        }}
                    />
                    <Button
                        color="dark"
                        className="btn-primary"
                        onClick={handleOnApply}
                        disabled={!form.startDate || !form.endDate || !form.moduleOption}
                    >
                        <i className="fa fa-search"></i>&nbsp;Search
                    </Button>
                </div>
            </div>
        )
    }

    if (popupView)
    {
        return (
            <PopupView modalHeader={title} isOpen={display} toggle={toggleDisplay}>
                {
                    loading ? <Spinner text={`Loading ${title}`} /> :

                    <>
                        {showDetailedView === true && <LogDetailsView />}
                        {showToolbar === true && <ToolBar />}
                        <AuditLogTable
                            data={state.logs}
                            onClickExpand={handleOnLogExpandClick}
                            onRowDoubleClicked={handleOnLogExpandClick}
                            showOptionsCol={showDetailedView}
                        />
                    </>
                }
            </PopupView>
        )
    }

    return (
        <div>
            {showDetailedView === true && <LogDetailsView />}
            {showTitle && (<div className="fs-4 fw-semibold mb-3">Audit Logs</div>)}
            {showToolbar === true && <ToolBar />}
            {showDivider && (<Divider className="mb-4" />)}
            {
                loading ? <Spinner text={`Loading ${title}`} /> :
                    <AuditLogTable
                        data={state.logs}
                        onClickExpand={handleOnLogExpandClick}
                        onRowDoubleClicked={handleOnLogExpandClick}
                        showOptionsCol={showDetailedView}
                    />
            }

        </div>
    );
}

const Divider = styled.div`
    height: 1px;
    background-color: #00000010;
    width: 100%;
`;

const CustomSelect = styled(Select)`
    width: 250px;

    .Select-control {
        height: 100%;
    }
    .Select-value, .Select-placeholder {
        display: flex;
        align-items: center;
    }
    .Select-multi-value-wrapper {
        height: 100%;
    }
`;


function PopupView({ modalHeader, isOpen, children, toggle })
{
    return (
        <Modal isOpen={isOpen} size="xl" toggle={toggle}>
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}