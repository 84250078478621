
import React, { Component } from 'react';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import Imgix from "react-imgix";

class CdnImage extends Component 
{

    render()
    {
        var grayScaleTransformation = null;
        if (this.props.grayScale)
            grayScaleTransformation = <Transformation effect="grayscale" />

        if(this.props.publicId && this.props.publicId.includes("imgix.net"))
        {
            return <div align="middle">
                <Imgix src={this.props.publicId} 
                    {...this.props}
                    imgixParams={{
                        q: 100,
                        crop: "focalpoint",
                        fit: "crop",
                        grayscale: this.props.grayScale ? 1 : 0
                    }}
                    alt="Image"                    
                />
            </div>

        }

        return (<CloudinaryContext cloudName={this.props.cloudName}>
                    <div align="middle">
                        <Image gravity="custom" publicId={this.props.publicId}>
                            <Transformation quality="100" {...this.props} gravity="custom" crop="fill" />
                            {grayScaleTransformation}
                        </Image>
                    </div>
                </CloudinaryContext>);
    }
}

export default CdnImage;